/* UnderMaintenance.css */
.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #E7F2F6; /* Keeps the existing background color */
}

.maintenance-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.settings-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.maintenance-card h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.maintenance-card p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.maintenance-card button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #0A80A0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.maintenance-card button:hover {
  background-color: #006d86;
}
