/**
* Template Name: Arsha - v4.10.0
* Template URL: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wdth,wght@0,62.5..100,100..900;1,62.5..100,100..900&display=swap');
@import '~@fortawesome/fontawesome-free/css/all.min.css';
body {
  font-family: "Noto Sans", sans-serif;
  color: #444444;
}

.MuiTypography-body1 .MuiListItemText-primary{
  font-family: "Noto Sans", sans-serif !important;
  color:#fff !important;
  font-weight:300 !important;
}
.date-input-container {
  display: flex;
  align-items: center;
}

.date-input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
  width: 100%;
}

.calendar-icon {
  font-size: 1.2em;
  color:#bbbcbc6b;
  cursor: pointer;
  margin-left: -30px;
}
.finish{
  padding:10px;
}
.btalignleft{
text-align: end;

}
.pd-0 {
padding-left: 0px !important;
padding-right: 0px;
}

.finish1{
  padding:10px;
  font-size:30px;
  color: green;
}
.form-eco {
background: #fff;
padding: 0px;

}
.report-style-class {
  height: 600px;
  width: 100%;
}

.inner-card {
  background: #fff;
  padding: 15px;
  border-radius:8px;
  display: flex;
  box-shadow: 0px 6px 12px #00000029;
  height: 140px;
}
.inner-text{
  margin: auto 0;
}
.inner-img{
  padding-left:5px;
  padding-right:2px;
  margin: auto 0;
}
.home-text{
  margin:auto 0;
  font-size:13px;
  font-weight: 400;
  line-height: 18px;
}
.hometab-text{
  color: #fff;
    padding: 12px 0px 12px 8px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 2px 3px 3px 0px #a1a1a1;
    color:#494949;
}
.hometab-text {
  transition: background-image 0.3s, color 0.3s;
  /* Default styles */
  background-image: none;
  color: #494949; /* Default font color */
}

.hometab-text:hover {
  background-image: linear-gradient(to right, rgba(64, 119, 117, 0.76), rgba(64, 119, 117));
  color: white; /* Font color on hover */
}

.home-head{
  font-weight: 700;

}
.wd-25{
  width: 50px;
}
.shadow-sm{
  border:0;
  min-height: 410px;
  height: 410px;
  margin-bottom: 10px;
}
.climate-clock-container {
  width: 100%;
  height: 150px; /* Adjust the height as per your requirements */
  overflow: visible;
  position: relative;
}



.climate-clock-container iframe .logo,
.climate-clock-container iframe .background-image {
  display: none !important;
}
.climate-clock-container iframe + div {
      display: none;
    }
.ccw-w[size=lg] ccw-readout{
  font-size: 35px !important;
}
[data-header-style="theme"].header{
  background-color:#f8f8f8 !important;
}
.header .header-announcement-bar-wrapper{
  background: #f8f8f8 !important;
}
.App {
  text-align: center;
}
.embed-container{
height: 800px !important;
width: 800px !important;
}
.flex {
  display: flex;
  margin-bottom:1px;
}
.flex-pd {
  display: flex;
  margin-bottom:20px;
  padding:0;
}
.pd-0{
  padding: 0px !important;
}
.table thead th{
  font-weight: 600;
  background-color: #fff;
  font-family: "Noto Sans", sans-serif;
  vertical-align: middle;
  border-bottom: 1px solid #cecece !important;
}

.table tbody tr{
  vertical-align: middle;
  background-color: #fafafa !important;
  border-bottom: 2px solid #fff !important;
}

.table tbody th{
  vertical-align: middle;
}

.submenu{
  margin-left: 54px;
  display: inline-block;
}

.submenu ListItem{
  font-size: 14px;
}
.box{
  margin-bottom: 10px;
}
.btn-box {
  margin-left: 10px;
  width: 200px;
  display: inline-block;
  text-align: left;
}
.ml10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
a {
  color: #fff;
  text-decoration: none;
}
p{
  font-family: 'Noto Sans' !important;
}
a:hover {
  color: #707070;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans", sans-serif;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: transparent radial-gradient(closest-side at 16% 26%, #407775 0%, #407775B0 100%) 0% 0% no-repeat padding-box;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #37517e;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6bc1e9;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}


/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
}

#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(40, 58, 90, 0.9);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #fff;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: -110px;
  top: calc(100% + 30px);
  margin: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

/* .navbar .dropdown li {
  
} */
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #fff;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    align-items: self-start;
    /* display: none; */
  }
  .custome__header .navbar-collapse{
    padding-left: 24px;
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 80px;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #37517e;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #fff;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #fff;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background:  transparent radial-gradient(closest-side at 16% 26%, #407775 0%, #407775B0 100%) 0% 0% no-repeat padding-box;
}

#hero .container {
  padding-top: 72px;
  max-width: 100%;
}
.main-div {
  padding-left: 115px;
}

#hero h1 {
  margin: 0 0 -9px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  top: 341px;
  left: 145px;
  width: 547px;
  height: 81px;
  /* UI Properties */
  font: var(--unnamed-font-style-normal) normal bold 60px/71px 'Noto Sans';
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal bold 60px/71px 'Noto Sans';
  letter-spacing: 1.4px;
  color: #FFFFFF;
  opacity: 1;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 50px;
  font-size: 24px;
  top: 426px;
  left: 145px;
  width: 510px;
  height: 51px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 50px/55px 'Noto Sans';
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 43px/55px 'Noto Sans';
  letter-spacing: 2.5px;
  color: #FFFFFF;
  opacity: 1;
}

#hero h3 {
  top: 526px;
  left: 145px;
  width: 562px;
  height: 113px;
  /* UI Properties */
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 24px/30px 'Noto Sans';
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal  24px/30px 'Noto Sans';
  letter-spacing: 1.4px;
  color: #FFFFFF;
  opacity: 1;
}

#hero h5 {
  top: 815px;
  left: 144px;
  width: 250px;
  height: 22px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 22px/55px 'Noto Sans';
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 15px/41px 'Noto Sans';
  letter-spacing: 1.1px;
  color: #FFFFFF;
  opacity: 1;
}

.img-txt {
  top: 35px;
  left: 96px;
  width: 100%;
  height: 60px;
  /* UI Properties */
  opacity: 1;
  margin: 0px 12px;
  padding: 14px;
}


 
 
.landing-div {
  background: transparent radial-gradient(closest-side at 16% 26%, #407775 0%, #407775B0 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000029;
  opacity: 1;
}




#hero .btn-watch-video {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  line-height: 1;
}

#hero .btn-watch-video i {
  line-height: 0;
  color: #fff;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#hero .btn-watch-video:hover i {
  color: #fff;
}

#hero .animated {
  margin-left: 9px;
  /* animation: up-down 2s ease-in-out infinite alternate-reverse both; */
}

@media (max-width: 991px) {
  #hero {
    height: 100vh;
    text-align: center;
  }

  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }

  #hero .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
/* section {
  padding: 60px 0;
  overflow: hidden;
} */
 

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #37517e;
}

.connecter-h6 {
  top: 373px;
  left: 976px;
  width: 189px;
  height: 26px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 22px/26px var(--unnamed-font-family-'Noto Sans', sans-serif);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-2a2828);
  text-align: left;
  font: normal normal normal 20px/26px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #2A2828;
  opacity: 1;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #fff;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 12px 0;
  text-align: center;
}

.clients img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(100);
}

.clients img:hover {
  filter: none;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li+li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #fff;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.3s;
  line-height: 1;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #fff;
}

.about .content .btn-learn-more:hover {
  background: #fff;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
  padding: 60px 100px 0 100px;
}

.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #37517e;
}

.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.why-us .content p {
  font-size: 15px;
  color: #848484;
}

.why-us .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.why-us .accordion-list {
  padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}

.why-us .accordion-list li+li {
  margin-top: 15px;
}

.why-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.why-us .accordion-list a {
  display: block;
  position: relative;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
}

.why-us .accordion-list span {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
  display: none;
}

.why-us .accordion-list a.collapsed {
  color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
  color: #fff;
}

.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {

  .why-us .content,
  .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .why-us .img {
    min-height: 400px;
  }

  .why-us .content {
    padding-top: 30px;
  }

  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .why-us .img {
    min-height: 200px;
  }
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #37517e;
  font-family: "Noto Sans", sans-serif;
}

.skills .content ul {
  list-style: none;
  padding: 0;
}

.skills .content ul li {
  padding-bottom: 10px;
}

.skills .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #fff;
}

.skills .content p:last-child {
  margin-bottom: 0;
}

.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  color: #37517e;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #e8edf5;
  height: 10px;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #4668a2;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}

.services .icon-box .icon {
  margin-bottom: 10px;
}

.services .icon-box .icon i {
  color: #fff;
  font-size: 36px;
  transition: 0.3s;
}

.services .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #37517e;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  transform: translateY(-10px);
}

.services .icon-box:hover h4 a {
  color: #fff;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9));
  background-size: cover;
  padding: 120px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #fff;
  border: 2px solid #fff;
}

@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  list-style: none;
  margin-bottom: 20px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  margin: 10px 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  transition: all 0.3s;
  padding: 8px 20px;
  border-radius: 50px;
  font-family: "Noto Sans", sans-serif;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #fff;
  color: #fff;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-img {
  overflow: hidden;
}

.portfolio .portfolio-item .portfolio-img img {
  transition: all 0.6s;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 15px;
  bottom: 0;
  z-index: 3;
  right: 15px;
  transition: all 0.3s;
  background: rgba(55, 81, 126, 0.8);
  padding: 10px 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #f9fcfe;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #fff;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #fff;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item:hover .portfolio-img img {
  transform: scale(1.15);
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #fff;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(55, 81, 126, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 5px;
  background: #fff;
  transition: 0.5s;
}

.team .member .pic {
  overflow: hidden;
  width: 180px;
  border-radius: 50%;
}

.team .member .pic img {
  transition: ease-in-out 0.3s;
}

.team .member:hover {
  transform: translateY(-10px);
}

.team .member .member-info {
  padding-left: 30px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #37517e;
}

.team .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.team .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #cbd6e9;
  bottom: 0;
  left: 0;
}

.team .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #eff2f8;
}

.team .member .social a i {
  color: #37517e;
  font-size: 16px;
  margin: 0 2px;
}

.team .member .social a:hover {
  /* background: #fff; */
}

.team .member .social a:hover i {
  color: #fff;
}

.team .member .social a+a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
  background: #fff;
  height: 100%;
  border-top: 4px solid #fff;
  border-radius: 5px;
}

.pricing h3 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 20px;
  color: #37517e;
}

.pricing h4 {
  font-size: 48px;
  color: #37517e;
  font-weight: 400;
  font-family: "Noto Sans", sans-serif;
  margin-bottom: 25px;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: #fff;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0 10px 30px;
  position: relative;
}

.pricing ul i {
  color: #28a745;
  font-size: 24px;
  position: absolute;
  left: 0;
  top: 6px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  display: inline-block;
  padding: 12px 35px;
  border-radius: 50px;
  color: #fff;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Noto Sans", sans-serif;
  transition: 0.3s;
  border: 1px solid #fff;
}

.pricing .buy-btn:hover {
  background: #fff;
  color: #fff;
}

.pricing .featured {
  border-top-color: #fff;
}

.pricing .featured .buy-btn {
  background: #fff;
  color: #fff;
}

.pricing .featured .buy-btn:hover {
  background: #23a3df;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li+li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #fff;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #37517e;
  transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
  color: #fff;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.contact .info i {
  font-size: 20px;
  color: #fff;
  float: left;
  width: 44px;
  height: 44px;
  background: #e7f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #37517e;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #6182ba;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #fff;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #fff;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form label {
  padding-bottom: 8px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #fff;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #fff;
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #209dd8;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f3f5fa;
  min-height: 40px;
  margin-top: 72px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 68px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 600;
  color: #37517e;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4668a2;
  content: "/";
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  font-size: 14px;
  background: #37517e;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #f3f5fa;
  text-align: center;
  font-size: 15px;
  color: #444444;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #37517e;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  text-align: left;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #fff;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type=submit]:hover {
  background: #209dd8;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  color: #37517e;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Noto Sans", sans-serif;
  color: #5e5e5e;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #37517e;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #fff;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #209dd8;
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
}

#footer .copyright {
  float: left;
}

#footer .credits {
  float: right;
  font-size: 13px;
}

#footer .credits a {
  transition: 0.3s;
}

@media (max-width: 768px) {
  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }

  #footer .credits {
    padding-top: 4px;
  }
}
.icon-list a{
  color: #FFFFFF !important;
  display: inline-flex;
  padding: 10px;
}
.icon-list {
  margin-top: 21px;
}
.icon-list-item {
  justify-content: center !important;
  color: aliceblue !important;
  
}
.icon-list-active,.icon-list-active:hover {
  text-decoration: none;
  background-color: #fff !important;
  color:#407775 !important; 
  width:220px !important;
  border-radius: 10px !important;
  margin-left:12px !important;
  padding-left: 0px !important;
}
.icon-list-active-submenu,.icon-list-active-submenu:hover {
  text-decoration: none;
  background-color: #fff !important;
  color: #407775 !important;
  /* width: 180px !important; */ /*Comment this*/
  border-radius: 10px !important;
  /* margin-left: 20px !important; */
  padding-left: 8px !important;
}
.icon-list-active-submenu span{
  color:#407775 !important;
  font-weight: 400 !important;
  padding-left: 7px !important;
}
.icon-list-active span{
  color:#407775 !important;
  font-weight: 400 !important;
 
}
.icon-list-item span{
  align-items: center;
  align-content: center;
  display: inline-block;
}
.list-icon{
  min-width:35px !important;
  margin-left: 20px;
  color: #fff !important;
  /* color: #464775 !important; */
}
.icon-color {
  color: #407775E8 !important;
}
.icon-color span {
 
  text-align: left;
  font: normal normal normal 18px/21px 'Noto Sans';
}
.list-icon span {
  padding: 0px !important;
}
.icon-text{ 
  text-align: center;
      margin-top: 0px;
     margin-bottom: 0px;
  /* color: #464775; */
}
.right-div {
  position: fixed;
    left: 300px;
}
.icon-text-active {
  color: #00366d;
}
.excel-div {
  top: 457px;
  left: 985px;
  width: 215px;
  height: 194px;
  text-align: center;
  
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.excel-div img {
  top: 486px;
  left: 1031px;
  width: 200px;
  height: 135px;
  padding-top: 29px;
  /* UI Properties */
  opacity: 1;
}
.main-div {
  display: "flex";
  flex-direction: "column";
  flex-grow: 1;
  height: "100vh";
  overflow: "auto";
} 
.login-header {
  left: 200px;
  position: fixed;
  top: 10px;
}
/* .profile-menu .MuiMenu-paper{
  top: 53px !important;
  left: 1264px !important;
} */
.tender-list {
  margin-left: 22px;
}
.three-dots {
  text-align: right;
  margin: auto;
}
.rfx-text {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  display: flex;
  letter-spacing: 0.15px;
  color: #757575;
}
.org-head {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height, or 190% */
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #464775;
}
.tender-list-div {
  left: 66px;
  top: 48px;
  border: 1px solid #000000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.details-text {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #464775;
}
.icon-black {
  /* color: #000000 !important; */
}
.ms-action-btn {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
 

#demo-multiple-name {
  min-width: 142px;
  top: 409px;
  left: 976px;
  width: 376px;
  height: 4px;
  background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #40777526;
  border-radius: 5px;
  opacity: 1;
}

.outline-btn {
  color: #fff;
  border:1px solid #407775 !important;
  text-transform: capitalize !important;
}
 

/* .logo-img {
  top: 20px;
  left: 118px;
  width: 150px;
  height: 100%;
  position: fixed;
} */
.log-div {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 1200px;
}
.log-div-h5 {
  font: normal normal bold 23px/33px 'Noto Sans', sans-serif;
    letter-spacing: 0.56px;
    color: #407775;
    opacity: 1;
    text-align: center;
}
.excel-select {
  border: 1px solid;
}
.login-btn {
  top: 20px;
  right: 118px;
  width: 150px;
  height: 100%;
  position: fixed;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.Ecodropdown{
  padding:7px;
  border: 1px solid #40777526;
  border-radius: 5px;
  min-height: 40px;
  font: normal normal normal 14px/24px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #707070;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  /* Hide the default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Set a custom arrow character (you can change this to any Unicode character) */
  background: url('data:image/svg+xml;utf8,	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="chevron"><path d="M13.418 7.859a.695.695 0 0 1 .978 0 .68.68 0 0 1 0 .969l-3.908 3.83a.697.697 0 0 1-.979 0l-3.908-3.83a.68.68 0 0 1 0-.969.695.695 0 0 1 .978 0L10 11l3.418-3.141z"></path></svg>') no-repeat;
  background-position: calc(100% - 18px) center; /* Adjust the position as needed */
  background-size: 18px; /* Adjust the size as needed */
  /* Add a background color */
  background-color: #f5f5f5; /* Adjust the color as needed */
  width: 100%; /* Adjust the width as needed */
}
/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	background-color: #fff;
}
 
.table td, .table th{
  font-family: "Noto Sans", sans-serif;
    color: #707070;
    font-size: 13px !important;
    line-height: 16px !important;
    vertical-align: sub;
}
.mt-0-ul ul {
  margin-bottom: 0;
}
.add-cta{
  color: #407775;
    font-weight: 600;
    font-size: 16px;
    padding-left: 0px;
}
.add-cta:hover{
  color: #407775;
  font-weight: 600;
  font-size: 16px;
  padding-left: 0px;
}
.refresh-text{
  font-size:12px;
}
.last-refresh{
  padding: 15px 15px 0px 15px;
}
.refresh-right{
  float:right;
}
.dropdown-item:hover{
  background-color: #407775;
}
.swal2-confirm button{
  background-color: #407775 !important;
}
.swal2-styled{
  background-color: #407775 !important;
}

.swal2-title{
  font-size:20px !important;
  font-weight:400 !important;
  margin-bottom: 15px;
}
.swal2-popup{
  width:400px;
  border-radius: 27px !important;
}
.swal2-icon{
  margin: 2.4em auto 0.6em !important;
}
.swal2-actions{
  margin: 1.4em auto 5px !important;
}
.mark-form{
  color: #a1a1a1;
}
.css-a9ecwz-MuiButtonBase-root-MuiButton-root:hover{
  background-color:#6F9896 !important;
  font-family: 'Noto Sans';
}
.css-a9ecwz-MuiButtonBase-root-MuiButton-root{
  background-color: #407775 !important;
  font-family: 'Noto Sans';
}
.css-a9ecwz-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.css-17mjc6-MuiButtonBase-root-MuiButton-root{
  border: 1px solid #407775 !important;
  font-family: 'Noto Sans';
  color: #707070;
}
	.file-input {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		cursor: pointer;
	}
  .dropzone-container {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    left: 50%;
    width: 100%;
    text-align: center;
  }

  .dropzone-container1 {
    margin: 0;
    position: relative;
    top: 10%;
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    left: 50%;
    width: 100%;
    text-align: center;
  }


.file-icon {
	/* Need position to allow stacking of pseudo-elements */
  text-align: center;
	/* Padding for demo purposes */
	
}


/* My Css::Start */
.login__custome-header{
  background-color: #407775;
  box-shadow: 0px 6px 12px #00000029;
}
.custome__header{
  background: #fff 0% 0% no-repeat padding-box;
  min-height: 60px;
  max-height: 60px;
  box-shadow: 0px 0px 10px #00000029;
}
.custome__header .navbar-collapse{
  padding-left: 2px;
}
.navbar-toggler{
  margin-left: auto;
  margin-right: 10px;
}
/* Login Page Css::Start */
.login__wrapper .custome__header{
  background-color: #407775;
}
.login__wrapper .login__inner{
  box-shadow: 0px 6px 12px #00000029;
  width: 100%;
  max-width: 1030px;
  margin: 26px auto;
  display: flex;
  padding: 80px 80px;
}
.login__left{
  width: 100%;
  max-width: 100%;
}
.login__left .login__left-img{
  width: 100%;
  max-width: 410px;
}
.login__right{
  width: 100%;
  max-width: 100%;
  padding: 0 70px;
}
.login__title{
  text-align: center;
  font: normal normal bold 20px/33px 'Noto Sans', sans-serif;
  letter-spacing: 0.56px;
  color: #407775;
  border-bottom: 2px solid #EDF0F4;
  padding-bottom: 7px;
  margin-bottom: 8px;
}


.signin__btn{
  width: 100%;
  max-width: 221px;
  min-height: 45px;
  margin: 15px auto;
  font: normal normal normal 16px/21px 'Noto Sans', sans-serif;
  letter-spacing: 0.63px;
  color: #FFFFFF;
  display: block;
}

.forgot__text{
  font: normal normal normal 16px/19px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #707070 !important;
  text-align: center;
  display: block;
}
.or__text{
  font: normal normal normal 16px/19px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #707070;
  text-align: center;
  margin: 15px auto 0px auto;
  display: flex;
  align-items: center;
}
.or__text::after{
  content: '';
  height: 1px;
  background-color: #407775;
  width: 100%;
  display: inline-block;
  margin-left: 10px;
}
.or__text::before{
  content: '';
  height: 1px;
  background-color: #407775;
  width: 100%;
  display: inline-block;
  margin-right: 10px;
}
.google__btn{
  font: normal normal normal 16px/19px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #2A2828;
  background: #2A2828 0% 0% no-repeat padding-box;
  border: 1px solid #40777540;
 width:300px;
  display: block;
  min-height: 45px;
  padding: 0 30px;
  margin-left: 0px;
}
.google__btn img{
  max-width: 28px;
  margin-right: 15px;
}
.google__btn:hover{
  border: 1px solid #40777540;
}
/* Comman Css::Start */
.form-label{
  font: normal normal normal 14px/19px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #494949;
}
.form-control{
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #40777526;
  min-height: 40px;
  margin-bottom:5px;
  font-size: 14px !important;
  line-height: 24px !important;
  color:#707070 !important;

}
.form-control:focus{
  box-shadow: none !important;
  border-color: #407775 !important;
}
.form-control input{
color:#707070 !important;
}
.formimg{
  position: fixed;
  bottom: 0;}
.formlable{
font-weight: 600;
font: normal normal normal 14px/24px 'Noto Sans', sans-serif;
}
.formpd{
  padding-left: 50px;
  padding-right: 50px;
    padding-bottom: 25px;
}
.dashbx{
  background-color: aliceblue;
  padding: 15px;
  border-radius: 20px;
  text-align: center;
  margin-right: 15px;
  margin-bottom:15px;
}
.mb-50{
  margin-bottom: 50px;
}
.mb-25{
  margin-bottom: 25px;
}
.mb-10{
  margin-bottom: 10px;
}
.mt-50{
  margin-top: 50px;
}
.mt-25{
  margin-top: 25px;
}
.mt-23{
  margin-top: -24px;
}
.mt-top10{
  margin-top: 10px;
}
.mt-10{
  margin-bottom: 10px;
}
.mt-15{
  margin-top: 15px;
}
.mt-20{
  margin-top: 20px;
}
.txt-end{
  text-align: end;
}
.pd-lr-50{
  padding-left: 50px;
  padding-right: 50px;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
  padding:0px !important;
}
.css-dmmspl-MuiFormGroup-root{
  margin-left:20px;
}
.css-nrxgm2-MuiTypography-root-MuiLink-root:hover{
  color: #494949;
}

.form-text{
  font: normal normal normal 12px/19px 'Noto Sans', sans-serif;
  color: red;
}
.form-check-label{
  font: normal normal normal 14px/19px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #707070;
}
.btn-primary{
  background: #407775 0% 0% no-repeat padding-box !important;
  border-color: #407775 !important;
  font-size: 0.875rem !important;
}
.btn-primary:hover{
  opacity: 0.9;
  background-color: #407775;
  font-size: 0.875rem !important;
}
.btn-primary:focus {
  color: #fff;
  background-color: #407775 !important;
  border-color: #407775 !important;
  font-size: 0.875rem !important;
}
/* .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 768px){
.col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px){
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
} */
.profile__pick{
  width: 45px;
  height: 45px;
  max-width: 45px;
  border-radius: 50px;
  background-color: #ccc;
}
.profile__pick img{
  max-width: 100%;
}
.form-select{
  background: #F5F5F5 0% 0% no-repeat padding-box;
border: 1px solid #40777526;
border-radius: 5px;
  min-height: 53px;
  font: normal normal normal 16px/26px 'Noto Sans';
  letter-spacing: 0px;
  color: #4B4848;
  text-transform: capitalize;
}


/* Sidebar Css::Start */

.img-logo {
  max-width: 195px;
  padding-left: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 45px; 
  height: 45px;
}
.logo-sidebar {
  width: 100%;
  max-width: 200px;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;

}
.sidebar-close{
  width: 70% !important;
  margin: 0 auto !important;
}
.sidebar-close .navbar-brand{
  display: none !important;
}
.sidebar-close .text{
  display: none;
}
.sidebar-open .brand_icon{
  display: none;
}
.brand_icon img{
  max-width: 45px;
  margin: 0 auto;
  display: block;
}
.sidebar-close .toggle__button{
  margin-top:0px;
}
.page-content-wrapper-pd-0{
  padding-left: 0px !important;
  background-color: #E9EFEF;
}

@media(min-width:992px) {
  /* #wrapper {
      padding-left: 250px;
  } */

  #wrapper.toggled {
      padding-left: 0;
  }

  #sidebar-wrapper {
      width: 300px;
  }


  #wrapper.toggled #sidebar-wrapper {
      width: 0;
  }

  /* #page-content-wrapper {
      padding: 20px;
      position: fixed;
  } */

  #wrapper.toggled #page-content-wrapper {
      position: relative;
      margin-right: 0;
  }
  #page-content-wrapper{
    background: #E9EFEF 0% 0% no-repeat padding-box;
  }
}
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  /* margin-top: 65px; */
}
.toggle__button{
  border-top-left-radius: 9px;
  border-bottom-left-radius: 8px;
  position: absolute;
  top: 65px;
  right: 0px;
  padding: 0;
  cursor: pointer;
}
.toggle__button .icon{
  max-width: 25px;
  margin: 3px 0;
}
.left__sidebar .navbar-brand{  
  padding: 0 15px;
  margin: 0 auto;
  display: block;
  text-align: center;
}
.left__sidebar{
  padding-top: 15px;
}
.left__sidebar-list{
  list-style: none;
  margin: 0px;
  padding: 0px;
  padding-top: 50px;
}
.left__sidebar-list .items a{
  font: normal normal normal 16px/26px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 14px 10px 14px 30px;
}
.left__sidebar-list .items a.active,.left__sidebar-list .items a:hover{
  color: #407775;
  background-color: #fff;
}
.left__sidebar-list .items a.active .icon-white{
  display: none;
}
.left__sidebar-list .items a.active .icon-hover{
  display: block;
}
.left__sidebar-list .items a:hover .icon-white{
  display: none
}
.icon-hover{
  display: none;
}
.left__sidebar-list .items a:hover .icon-hover{
  display: block;
}
.left__sidebar-list .items .icon{
  max-width: 20px;
  margin-right: 7px;
}
.sidebar-close {
  width:100% !important;
}

.sidebar-close .left_icon{
  display: none;
}
.sidebar-open .right_icon{
  display: none;
}
/* Sidebar Css::End */
/* Header Css::Start */
.custome__header .navbar-nav .nav-item .nav-link{
  color: #fff;
  font: normal normal normal 20px/21px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
}
.custome__header .navbar-nav .nav-item .nav-link .icon{
  height: 22px;
  margin-right: 2px;
}
.custome__header .profile__pick{
  margin-left: 7px;
}
.notification__number{
  width: 23px;
  height: 23px;
  border-radius: 50px;
  background-color: #407775;
  font: normal normal normal 12px/19px 'Noto Sans', sans-serif;
  letter-spacing: 0.32px;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Header Css::End */

/* Stepes Css::Start */
.steps__containt-hader{
  margin-bottom: 10px;
  margin-top: 20px;
}
.revized__containt-header {
  padding-left: 25px;
  margin-top: 20px;
  border-bottom: 1px solid #40777526;
}
.steps__container{
  position: relative;
}
.revized__container{
  padding-left: 25px;
  position: relative;
}
.revized__side__container{
  display: flex;
  flex-direction: row;
}
.child-div-10{
  width: 7%;
  max-width: 7%;
  min-width: 70px;
  height: 100vh;
  padding-top: 40px !important;
  background-color: #F5F5F5 !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
}
.clickable-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
}
/* after selecting available connector at the sidebar*/
.selected {
  background-color: #B4C9C8;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.selected::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%); 
  border-width: 10px 0 10px 15px;
  border-style: solid;
  border-color: transparent transparent transparent #B4C9C8;
}

.child-div-10 img {
  max-width: 70px; 
  width: 100%; 
  margin: auto; /* make center*/
}
.child-div-10 label {
  margin: auto; /* horizantal center alignment */
}
.child-div-90{
  width: 93%;
  max-width: 93%;
  padding-right: 2px;
  padding-left: 40px;
  padding-top: 40px;
}
.steps__container .form-label{
  margin-bottom: 25px;
}
.steps__containt-hader .title-sl{
  font: normal normal bold 15px/25px 'Noto Sans', sans-serif;
  letter-spacing: 0.52px;
  color: #494949;
  font-weight: 500;
}
.steps__containt-hader .title{
  font: normal normal bold 18px/31px 'Noto Sans', sans-serif;
  letter-spacing: 0.52px;
  color: #494949;
}
.revized__containt-header .title{
  font: normal normal bold 18px/31px 'Noto Sans', sans-serif;
  letter-spacing: 0.52px;
  color: #494949;
  font-size: 1.35rem !important;
}
.steps__containt-hader p{
  font: normal normal normal 14px/26px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #2A2828;
}
.revized__containt-header p{
  font: normal normal normal 14px/26px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #2A2828;
}
.breadcrumbs-font{
  font-size:14px !important;
}
.steps__containt-body{
  display: flex;
  align-items: flex-start;
}
.steps__container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
}
.revized_steps__container{
  display: flex;
  flex-direction: column;
  height:100%;
}
.revized_second_steps__container{
  display: flex;
  flex-direction: column;
  height:100%;
  padding-left: 25px;
}
.tick-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px;
  font-size: 16px;
  color: green; /* Tik rengini istediğiniz renge ayarlayabilirsiniz */
}
.steps__containt-left{
  width: 100%;
  max-width: 280px;
  min-width: 280px;
  /* box-shadow: 0px 6px 12px #00000029; */
  height: calc(100vh - 70px);
  padding-left: 30px !important;
}
 
.steps__containt-left .MuiStepLabel-label{
  font: normal normal normal 12px/18px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #2A2828;
}
.steps__containt-right{
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-left: 1px solid #dee2e6;
}
.revized__containt-right{
  padding-right: 25px;
  padding-bottom: 35px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-left: 1px solid #40777526;
}
.css-1wqbziq-MuiStepper-root{
  padding-left: 20px !important;
}
.css-y40rn3-MuiGrid-root{
  box-shadow: none;
}
.css-45fwg6-MuiGrid-root{
  box-shadow: none;
}
.file-icon .MuiSvgIcon-root {
  /* color: #fff !important; */
  /* border: 1px solid #407775; */
  border-radius: 50px;
  padding: 9px;
  background-color: #E6E9ED ;
}
.Mui-completed,.Mui-active{
  color: #407775 !important;
}

.MuiStepIcon-text {
  opacity: 0;
}
.next__previous-buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: inherit;
  bottom:15px;
  top:15px;
  padding-bottom: 35px;
  margin-top:70px;
}
.next__previous-buttons button{
  font-size: 14px;
}
.dropzone__list{
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.dropzone__list .items{
  width: 100%;
  display: inline-block;
  max-width: 100px;
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 4px;
  margin-bottom: 20px;
  margin-left:20px;
  border: 1px solid transparent;
  cursor: pointer;
  opacity: 1;
  position: relative;
}
/*custom checkbox*/
.custom-checkbox {
  position: relative;
  padding-right: 30px;
  display: block;
  cursor: pointer;
  font-size: 12px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #40777526;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #f5f5f5;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: green;
  border-color: green;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  right: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.dropzone__list .available{
  width: 100%;
  display: inline-block;
  max-width: 120px;
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
  margin-bottom: 15px;
  margin-left:15px;
  border: 1px solid transparent;
  cursor: pointer;
}
.dropzone__list .items.active{
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #40777580;
  opacity: 1;
}
/* custom popup design starts */
.custom-title-class {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #407775;
  border-radius: 27px 27px 0 0;
  padding: 10px 20px;
  box-sizing: border-box;
  position: absolute;
  top: 0; 
  left: 0; 
}
.custom-html-content {
  margin-top: 20px;
}
.custom-title-class h2 {
  font-size: 20px; /* Increase font size */
  color: #fff; /* Change text color */
  margin-bottom: 0px; 
  position: relative;
}
/* If I use this style it breaks other popups*/
/*.swal2-actions{
  margin-left: 20px !important;
}*/
#swal-input1 {
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}
.my-custom-popup {
  width: 817px !important;
  position: fixed;
  top: 30%;
  left: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
/* custom popup design ends */
/* allconfigurations modal design starts*/
@media (max-width: 1048px) {
  #modal-wrapper {
    max-width: 450px !important;
    margin-left: 5px !important;
    margin: 1.75rem auto;
  }
}
@media (max-width: 556px) {
  #modal-wrapper {
    max-width: 300px !important;
    margin-left: 1px !important;
    margin: 1.75rem auto;
  }
}
.custom-cell-p{
  margin: 0px;
  display: flex;
  align-items: center;
  height: 100%;
}
.modal-header .btn-close{
  color: #fff !important;
}
/*allconfigurations modal design ends*/
.dropzone__list .available.active{
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #40777580;
  opacity: 1;
}
.dropzone__section{
  cursor: pointer;
  margin-block-end: auto;
  width: 100%;
  max-width: 770px;
}
.dropzone__section .dropzone {
  width: 100%;
  height: 255px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #40777526;
  border-radius: 14px;
  opacity: 1;
  position: relative;
}
.dropzone__section .dropzone1 {
  width: 100%;
  height: auto;
  /* background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box; */
  border: 0px solid #40777526;
  border-radius: 14px;
  opacity: 1;
  position: relative;
}
.file-icon .MuiSvgIcon-root{
  background-color: #E3EAE9 !important;
  border: 1px solid #407775 !important;
}
.css-1x81pmn-MuiSvgIcon-root{
  color: #407775 !important;
}
.dropzone-title {
  font: normal normal normal 18px/26px 'Noto Sans';
  letter-spacing: 0px;
  color: #407775;
  margin-top: 7px;
}
.dropzone-title .browse{
  font: normal normal bold 18px/26px 'Noto Sans';
}
@media (min-width: 576px){
.modal-dialog {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
}
}
.hover-text {
  position: absolute;
  margin-top:2px;
  background-color: #407775;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
}
.choose__connector-title{
  font: normal normal normal 11px/20px 'Noto Sans';
  letter-spacing: 0px;
  color: #2A2828;
  margin: 10px 0 30px;
  font-weight: 600;
}
.dropzone__list .items h6{
  font: normal normal normal 8px/21px 'Noto Sans';
  letter-spacing: 0px;
  color: #4B4848;
  margin-bottom: 5px;
  font-weight: 600;
}
.dropzone__list .items img{
  max-width: 100%;
  margin-top: 5px;
}
/* Stepes Css::End */


/* Home Page Css::Start */
.home__page-wrapper{
  /* background: transparent radial-gradient(closest-side at 16% 26%, #407775 0%, #407775B0 100%) 0% 0% no-repeat padding-box; */
  box-shadow: 0px 6px 12px #00000029;

  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 50%;
  background-attachment: fixed;
}
.text-yellow{
  color: #fab430 !important;
}
.home__page-section{
  display: flex;
  min-height: calc(100vh - 0px);
  align-items: center;
  padding-top: 0px;
}
.green-logo{
  width:140px;
  padding-top: 25px; /* Add padding to the top */
  padding-bottom: 30px; /* Add padding to the bottom */
  padding-left: 30px; /* Add padding to the bottom */
  margin-right: 900px;
}

.green-lg{

}
.aichat-img{
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  padding: 80px; /* Add padding */
  margin: 0;
}
.linkedin-button{
  width: 250px;
}
.login-title{
  line-height: 25px;
  font-weight: 600;
  font-size: 20px; /* Adjust font size as needed */
  margin-top: 10px; /* Add margin on top for spacing */
  margin-bottom: 20px; /* Add margin at the bottom for spacing */
}
.home__page-section .title{
  font: 800 30px/35px 'Noto Sans', sans-serif;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 0px;
  color: #3D3D3D;
}
.home__page-section .sub__title{
  font:normal 600 45px/60px 'Noto Sans', sans-serif;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 0px;
  color: #f5f5f5;
}
.home__page-section p{
  font: 400 18px / 20px Noto Sans;
    letter-spacing: 1px;
    color: #3D3D3D;
    opacity: 1;
    margin: 0; /* Reset margin */
}
.dark-bg-ai{
  height:100vh;
  background: transparent linear-gradient(178deg, #F1F1F1AD 0%, #7979790D 100%) 0% 0% no-repeat padding-box;
}
.white-bg-login{
  background-color: #fff !important;
  height:100vh;
}
.login-box{
  display: flex; /* Use flexbox */
  flex-direction: column; /* Arrange children vertically */
  justify-content: center; /* Center content vertically */
  padding: 50px; /* Add padding */
  height: 100%; /* Ensure login box takes full height */
}
.home__page-section .btn-get-started{
  border: 1px solid #FFFFFF;
  border-radius: 16px;
  font:300 16px/22px 'Noto Sans', sans-serif;
  font-family: "Noto Sans", sans-serif;
  color: #f5f5f5;
  letter-spacing: 1.2px;
  min-width: 150px;
  max-width: 150px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 25px 0;
}
.warrior__text{
  font: 300 16px/30px 'Noto Sans', sans-serif;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 1.1px;
  color: #f5f5f5;
}
.warrior__text .a-login{
  text-decoration: underline;
  font:300 16px/30px 'Noto Sans', sans-serif;
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 1.1px;
  color: #f5f5f5;
}
.home__page-right-section img{
  max-width: 100%;
  text-align: right;
}
/* Home Page Css::End */
/* Home Page  Header Css::Start */
.home__page-wrapper header{
  background-color: #407775;
}
.home__page-wrapper .btn-login {
  font-family: "Noto Sans", sans-serif;
  padding: 10px 20px 10px 20px;
  font-weight: 300;
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 16px;
  opacity: 1;
  color: #f5f5f5;
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
  max-width: 150px;
}
/* Home Page  Header Css::End */
.hr-style{
  border-bottom: 1px solid #40777526;
}

/* Table Css::Start */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table1{
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  max-width:100%;
}
.custom-table tr td{
  background-color: #FAFAFA !important;
}
.addDefaultValue-p{
  margin-bottom: -10px;
  margin-right: 23px;
  text-align: end;
  font-size: medium;
  color: #407775;
  cursor: pointer;
  position: relative;
}
.table__section{
  padding-left: 50px;
}
.custom-table tr th {
  border-top: 0 solid #dee2e6;
  max-width: 300px;
  line-height: 24px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #000;
  border-bottom: 0 solid #dee2e6;
  padding: 0.75rem 10px;
  text-align: left;
  cursor: pointer;
  font-family: "Noto Sans", sans-serif;
}
.custom-table tbody td.c1 {
  /* font-weight: 500; */
  font: 500 14px/14px 'Noto Sans', sans-serif;
  line-height: 68px;
  height: 100%;
  vertical-align: middle;
  padding: 0px 20px 0px 10px;
  text-align: left;
  word-break: break-all;
}
.custom-table tbody td.c2 {
  /* font-weight: 500; */
  font: 500 14px/14px 'Noto Sans', sans-serif;
  line-height: 68px;
  height: 100%;
  vertical-align: middle;
  padding: 18px 20px 0px 0px;
  text-align: left;
  word-break: break-all;
}
/* Table Css::End */


.configuration__wrapper {}
.configuration__header{
  margin-bottom: 27px;
  border-bottom: 1px solid #1f4d7c12;
  padding: 0 8px;
}
.configuration__header .list{
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0px 0;
}
.configuration__header .list .items{
  color: #222;
  font: normal normal normal 14px/21px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  padding: 11px 12px;
  cursor: pointer;
}
.configuration__wrapper .tabs__title{
  font: normal normal bold 26px/31px 'Noto Sans', sans-serif;
  letter-spacing: 0.52px;
  color: #494949;
  padding-left: 8px;
}

.search__section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.search__section-right input{
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #40777526;
  padding: 5px 15px;
  font: normal normal normal 18px/21px 'Noto Sans', sans-serif;
letter-spacing: 0px;
color: #1E4B7A;
}
/* Tabs */
.configuration__wrapper .react-tabs__tab-list{
  border: 0px;
  margin-bottom: 40px;
}
.configuration__wrapper .react-tabs__tab{
  font: normal normal normal 16px/21px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #222;
  border: 0;
  box-shadow: 0px 6px 12px #00000029;
  outline: none;
  padding: 12px 15px;
  border-radius: 0px;
}
.react-tabs__tab--selected{
  background-color: #407775 !important;
  color: #FFFFFF !important;
}
.configuration__wrapper .custom-table tbody th{
  font: normal normal bold 20px/24px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #2A2828;
}
.configuration__wrapper .custom-table tbody td{
  font: normal normal normal 16px/21px 'Noto Sans', sans-serif;
  letter-spacing: 0px;
  color: #4B4848;
}
.configuration__wrapper .custom-table tbody td .link-text{
  color: #407775;
}

/* Media Css::Start */
@media screen and (max-width: 992px) {
  .custome__header{
    background-color: #407775;
  }
  .navbar-light .navbar-toggler{
    color: #fff;
    border-color: #fff;
  }
  .steps__container{
    min-height: 290px;
    height: auto;
  }
  .home__page-section .title{
    font-size: 25px;
  }
  .home__page-section .sub__title{
    font-size: 39px;
  }
  .home__page-section p{
    font-size: 15px;
  }
  /* .home__page-left-section{
    padding: 0px 0px 0px 0px;
  } */

  .login__wrapper .login__inner{
    flex-direction: column;
  }
  .login__wrapper .login__left{
    text-align: center;
  }
  .login__wrapper .login__right{
    padding: 0px;
  }
 /* For responsive layout */
  /*.steps__containt-body{
    flex-direction: column;
  }*/
 
  .steps__containt-left{
    /*max-width: 100%; */ /* For the responsive layout*/
    height: auto;
  }
  .steps__containt-left .table-responsive{
    max-height: calc(100vh - 150px);
  }
  .steps__containt-right {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .navbar-brand-for-mobile {
    display: none !important;

  }
}
.sidebar-image{
  width:28px;
  height:28px;
}
.MuiAvatar-root{
  background-color: transparent !important;
}
.css-19kzrtu{
  padding: 0px !important;
  margin-top:20px !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #407775 !important;
  font-weight: 600;
}
.css-1aquho2-MuiTabs-indicator {

  background-color: #000 !important;
}

/* Media Css::End */
.search-input{
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  font-size: 12px;
}
div:where(.swal2-icon).swal2-success [class^=swal2-success-line]{
  background-color: #407775 !important;
}
.active-buttongreen {
  background-color: #fff;
  color: #407775;
  border-top: none;
  border-right: none;
  padding-bottom: 10px;
  padding-left:0px;
  border-left: none;
  border-bottom:2px solid #407775;
}

.inactive-buttongreen {
  background-color: #fff;
  color: #ADA9A9;
  border-top: none;
  border-right: none;
  padding-bottom: 10px;
  padding-left:0px;
  border-left: none;
  border-bottom:none;
}
.active-buttonred {
  background-color: #fff;
  color: #CC2229;
  border-top: none;
  border-right: none;
  padding-bottom: 10px;
  padding-left:10px;
  border-left: none;
  border-bottom:2px solid #CC2229;
}

.inactive-buttonred {
  background-color: #fff;
  color: #F5979F;
  border-top: none;
  border-right: none;
  padding: 10px;
  border-bottom:none;
  border-left: none;
}

/* .modal-content{
  width:750px !important;
  margin: 0 auto !important;
  border-radius: 0px !important;
} */
.config-md{
  width:750px !important;
  margin: 0 auto !important;
  border-radius: 0px !important;
}
.modal-body{
  max-height:400px !important;
 overflow-y:auto !important;
 overflow-x: hidden !important;
}
.modal-backdrop.show {
  background: linear-gradient(180deg, rgba(64,119,117,1) 0%, rgba(250,180,48,1) 100%) !important;
  opacity: 0.6 !important;
}
.modal-footer{
  justify-content: flex-start !important;
  border-top: none !important;
}

div::-webkit-scrollbar {
  width: 6px;
  height:10px;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
}

div::-webkit-scrollbar-thumb {
background-color: #00000029;
outline: 0px solid #E3EAE9;
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #E3EAE9;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #407775;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #E3EAE9;  /* creates padding around scroll thumb */
}

.denied-img{
  width:125px !important;
  opacity: .70;
}

.center-screen{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.access-txt{
  margin-top:10px;
  font-size:20px;
  margin-bottom:10px;
}
.access-bg{
  background-color: #EEF1F5;
}
.dark-bg {
  background-color: #407775;
}
.MuiStepper-root{
  padding: 22px !important;
}
.next-button:hover{
  background-color: #407775 !important;
} 
.next-button{
  text-transform: capitalize !important;
  background-color: rgba(0, 0, 0, 0.26) !important;
}
.next-button:disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.landing-img{
 
  width: 65%; /* Set the width to auto to maintain aspect ratio */
  height: auto; /* Set the height to auto to maintain aspect ratio */
  max-width: 100%; /* Ensure image doesn't exceed its container's width */
  max-height: 100%; /* Ensure image doesn't exceed its container's height */
}
.pagination-container {
  display: flex;
  justify-content: flex-end; /* Adjust this value to align the pagination component as needed */
  align-items: center; /* Center vertically if needed */
  margin-top: 10px; /* Adjust the margin as needed */
  font-size: 13px !important;
  font-family: 'Noto Sans' !important;
}
.custom-table-pagination .MuiTablePagination-selectLabel {
  /* Style the rows per page select element */
  margin-right: 20px; /* Adjust the margin as needed */
  margin-top :1rem !important;
  font-size: 13px !important;
  font-family: 'Noto Sans' !important;
}

.custom-table-pagination .MuiTablePagination-displayedRows {
  /* Style the page input element */
  margin-left: 20px; /* Adjust the margin as needed */
  margin-top :1rem !important;
  font-size: 13px !important;
  font-family: 'Noto Sans' !important;
}

.custom-table-pagination .MuiTablePagination-actions {
  /* Style the pagination actions (previous and next buttons) */
  margin-left: auto; /* Push them to the right side of the container */
  font-size: 13px !important;
  font-family: 'Noto Sans' !important;
}
.custom-table-pagination .MuiTablePagination-select {
  font-size: 13px !important;
  font-family: 'Noto Sans' !important;
}
/* .MuiMenu-list[aria-labelledby=":r3:"] li.MuiMenuItem-root {
  font-size: 12px !important;
} */
.MuiMenu-list .MuiMenuItem-root
{
  font-size: 13px !important;
}
.icon{
  width: 22px;
  height: 22px;
}
.edit-form{
  padding-left: 50px;
  padding-right: 50px;
}
.MuiTableContainer-root
{
  box-shadow:none !important ;
}
.MuiTablePagination-toolbar{
  padding-left: 5px !important;
}
.custom-modal-width{
  max-width:550px !important;
  margin: 0 auto; /* Center the modal horizontally */

}
.custom-modal-width-config{
  max-width:800px !important;
  margin: 0 auto; /* Center the modal horizontally */
  
}
.custom-modal-width-delete{
  max-width:450px !important;
  margin: 0 auto; /* Center the modal horizontally */
  top: 25%;
  
}
.modal-content{
  border-radius:25px !important;
  border: none !important;
}
.text-conn{
  font-size:12px !important;
}

.search-container {
  display: flex;
  align-items: center;
  padding: 5px 10px; /* Adjust the padding as needed */
 
  border-radius: 5px; /* Add border radius as needed */
  font-size: 12px; /* Adjust font size as needed */
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 1px solid #E3EAE9;
  border-radius: 6px;
  opacity: 1;

}

.search-icon {
  margin-right: 10px; /* Adjust the margin as needed */
  color:#407775;
}

.search-input1 {
border: none !important;
background: #FAFAFA 0% 0% no-repeat padding-box;
opacity: 1;

}
input:focus {
  outline: none;
}
button:focus {
  outline: none;
  box-shadow: none !important;
}
.actions{
  display: flex;
  margin-right: 3px;
}
.btn-danger:focus{
  box-shadow: none !important;
  font-size: 0.875rem !important;
}
.btn:hover{
  border-color: #407775 !important;
  font-size: 0.875rem !important;
}
.btn-primary:hover{
  border-color: #407775 !important;
}
.btn-danger:hover{
  border:none !important;
  font-size: 0.875rem !important;
}
.btn-danger{
  
  font-size: 0.875rem !important;
}
.btn-primary:active{
  color: #fff !important;
  background-color: #407775 !important;
  border-color: #407775 !important;
}
.swal2-confirm:focus {
  box-shadow: none !important;
}
.MuiButtonBase-root-MuiButton-root:hover{
  border-color: #407775 !important; 
}
.btn .btn-primary {
  text-transform: capitalize;
}
.reportsearch{
  width: 100%;
}


/* styles.css */
.badgeRoot {
  position: relative;
}
.badgeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1rem;
  margin-bottom: 15px;
  margin-right: 23px;
}
.outerCircle {
  width: 2.6rem;
  height: 2.6rem;
  margin-bottom: 7px;
  position: absolute;
}
.outerCircle-editConfig {
  width: 2rem;
  height: 2rem;
  margin-bottom: 7px;
  position: absolute;
}

.innerCircle {
  width: 2.6rem;
  height: 2.6rem;
  margin-top: 1px;
  margin-bottom: 4px;
}

.innerCircleInactive {
  width: 2rem;
  height: 2rem;
  margin-top: 1px;
  margin-bottom: 8px;
}

.customIcon {
  width: 25%;
  position: absolute;
  padding-bottom: 7px;
}

.avatar {
  width: 4rem;
  height: 4rem;
  background-color: transparent;
}

.activeIcon {
  color: #407775;
}

.inactiveIcon {
  color: gray;
}
.image-container {
height: 200px; /* Adjust the desired height here */
overflow: hidden;

}
.image-container img {
width: 100%;
height: 200px;

}
.record-count{
  display: inline-block;
  background: #E9E9E9;
  border-radius: 50%;
  font-size: 12px;
  width: 25px;
  height: 25px;
  text-align: center;
  margin-right: 8px;
  line-height: 25px;
}
.config-btn{
  text-transform: capitalize;
}
.action-icon{
  width: 20px;
  height: 20px;
  margin-left:8px;
}

.edit-icon{
  width: 20px !important;
  height: 20px !important;
}
.wd-70{
  width:70%
}
.wd-80{
  width:80%
}
.wd-85{
  width:95%
}
.wd-100{
  width:100%
}
.wd-20{
  width: 15%;
}
.pd-left45{
  padding-left:36px;
}
.pd-left35{
  padding-left:30px;
}
.flex1{
  display: flex;
  font-size: 14px;
}
.checkbox-user{
  border: 1px solid #6F9896;
border-radius: 5px;
}
/* ****************************************************
*********************REPORTS************************
**************************************************** */

/* Standards.css */

/* Standards.css */

/* Add this CSS to your stylesheet */

.standards-container {
  position: relative;
  background: linear-gradient(181deg, #fab4308c, #ffffff14);
  padding: 20px;
  /* width: 90%; */
  margin: 0 auto;
}

.wd-50 {
  width: 60%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.standards-container .csrd-image img:hover {
  border: 2px solid #fab430;
}

/* .standards-container .other-image img {
  opacity: 0.5;
} */

.standards-container .other-image div {
  position: relative;
}

.standards-container .other-image .image-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 100%;
  background-color: white;
  opacity: 0.7;
  pointer-events: none;
}

/* Style for the small navigation arrows */
.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 10px !important;
  z-index: 1000;
  border: 0;
  background: rgba(255, 255, 255) !important;
  min-width: 23px !important;
  min-height: 23px !important;
  opacity: 1;
  cursor: pointer;
  padding: 5px 10px;
}

.react-multiple-carousel__arrow::before {
  font-size: 15px !important;
  color: #000 !important;
}
/* Style for the left arrow */
.standards-container .react-multiple-carousel__arrow--left {
  left: 0px; /* Position the left arrow */
}

/* Style for the right arrow */
.standards-container .react-multiple-carousel__arrow--right {
  right: 0px; /* Position the right arrow */
}

.csrd-text {
  background: linear-gradient(179deg, #b4c9c87a, #ffffff14);
}

.csrd-text a {
  color: #407775;
  font-weight: 600;
}

.csrd-form h5 {
  text-transform: uppercase;
  padding: 20px 0;
  font-weight: 600;
}

.main-acc-tab .MuiButtonBase-root.MuiTab-root,
.tab-container .MuiButtonBase-root.MuiTab-root,
.csrd-tabs .MuiTypography-root.MuiTypography-body1,
.left .MuiTypography-root.MuiTypography-body1,
.right .MuiTypography-root.MuiTypography-body1,
.centered-popup .MuiTypography-root.MuiTypography-body1 {
  font-family: "Noto Sans", sans-serif !important;
}

.csrd-tabs .MuiTypography-root.MuiTypography-body1,
.left .MuiTypography-root.MuiTypography-body1,
.right .MuiTypography-root.MuiTypography-body1,
.centered-popup .MuiTypography-root.MuiTypography-body1 {
  color: #407775;
  font-weight: 500 !important;
}

/* .css-1m4ljmh-MuiTypography-root:hover {
  font-weight: 500 !important;
} */

.csrd-tabs .acc-header {
  background: #40777599 linear-gradient(180deg, #ffffff 0%, #ffffff36 100%) 0% 0% no-repeat padding-box;
  color: #407775;
}

.csrd-tabs .acc-header.MuiButtonBase-root:hover {
  background: #40777599 linear-gradient(180deg, #ffffff 0%, #ffffff36 100%) 0% 0% no-repeat padding-box;
  /* color: #ebf1f1;*/
}

.csrd-tabs .MuiFormControlLabel-root {
  margin-left: 0;
  padding: 20px 10px 10px;
}

.csrd-tabs .MuiFormControlLabel-root .MuiFormControlLabel-label {
  margin-left: 15px;
  color: #707070;
}

.csrd-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}
.Mui-checked{
color: #407775 !important ;
}
.bg-grey{
  background: #F8F8F8;
}
/* CSS */
/* CSS */
.centered-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* This ensures that the popup takes the full height of the viewport */
  width: 80%; /* This ensures that the popup takes the full width of the viewport */
  position: fixed; /* This makes it fixed to the viewport */
  top: 0; /* Align it to the top of the viewport */
  left: -10%; /* Align it to the left of the viewport */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Ensure it's on top of other content */
}

.centered-popup .close-btn {
  background-color: transparent;
  border: 1px solid #407775;
  color: #407775;
  box-shadow: none;
  width:100px;
}

.centered-popup .close-btn:hover {
  color: #fff;
  background: #407775;
  border: 1px solid #407775;
}

.acc-header .MuiAccordionDetails-root {
  padding: 0 !important;
}

.left-container {
  background-color: #b4c9c87a;
  padding: 1px;
}

.left {
  margin: 15px;
}

.left .sub-accordion .MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 0;
  /* background-color: #407775; */
  /* color: #fff; */
}

.active-sub-accordion .MuiButtonBase-root.MuiAccordionSummary-root:hover:not(.Mui-disabled) {
  background-color: #fff; /* Set the background color */
  color: #fff; /* Set the font color */
}

.tab-container .MuiTabs-flexContainer {
  gap: 5px;
}

.tab-item.MuiButtonBase-root.MuiTab-root {
  align-items: start;
  background-color: #eff6f5;
  text-transform: none;
  min-height: 0;
}

.right {
  /* padding: 20px; */
}

.right .topic-title {
  background: rgb(255, 255, 255);
  background: linear-gradient(179deg, rgba(255, 255, 255, 1) 23%, rgba(242, 242, 242, 1) 100%);
  opacity: 1;
  padding: 15px 0 15px 40px;

  border: 1px solid rgba(242, 242, 242, 1);
}

.right .topic-title h6 {
  color: #494949;
  font-weight: 700;
  font-size: 1rem;
}

.right .questions-container {
  padding: 30px;
}

.right .question-tab {
  border: 1px solid #4077753b;
  border-radius: 0px 15px;
}

.right .question-box {
  background: #eff6f5 0% 0% no-repeat padding-box;
  border-top-right-radius: 15px;
  padding: 20px;
  opacity: 1;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #4077753b;
}

.right .question-box p {
  font-size: 0.875rem;
}

.right .answer-box textarea {
  border: none !important ;
  width: 100%;
  font-size: 0.875rem;
  padding: 20px;
  border-bottom-left-radius: 15px;
}

.stepper-box .MuiStepIcon-root {
  color: #b4c9c8;
  padding: 20px;
}

.stepper-box .Mui-disabled .MuiStepIcon-text {
  fill: #494949;
}

.stepper-box .MuiStepIcon-text {
  /* font-size: 0.6rem; */

  font-weight: 700;
}

.step-name .MuiStepIcon-root {
  border: 1.4px dashed;
  border-radius: 100%;
  padding: 2px;
  width: 1.3em;
  height: 1.3em;
}

.step-name .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  width: 70px;
  font-weight: 500;
}

.bold-part {
  font-weight: bold;
}
/* Create a flex container */
.tab-label-container {
  display: flex;
  align-items: center; /* Align items vertically centered */
  gap: 5px;
}

/* Add a background color to numbers */
.tab-number {
  padding: 2px 5px;
  border-radius: 20px;
}

.custom-date-picker,
.custom-start-date-picker,
.custom-end-date-picker {
  position: relative;
}

.full-width-datepicker {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%; /* Ensure the wrapper takes full width */
}

.react-datepicker__input-container {
  width: 100%; /* Ensure the input container takes full width */
}

.react-datepicker__input-container input {
  width: 100%; /* Ensure the input takes full width */
  padding-right: 30px; /* Adjust as needed to accommodate the custom icon */
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the right position as needed */
  transform: translateY(-50%);
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  cursor: pointer;
}

/* ------------------------------
   ReportTable.js
   ------------------------------ */

.actions-icon {
  cursor: pointer;
  height: 1em;
  margin-right: 8px;
}

.in-progress-icon {
  color: #fab430;
  height: 1em;
  margin-right: 8px;
}

.calendar-table-icon {
  height: 1em;
  margin-left: 8px;
  cursor: pointer;
}

.report-table.MuiTableContainer-root {
  font-family: Noto Sans, sans-serif !important;
  box-shadow: none;
}

.report-table thead,
.report-table thead .MuiTableRow-root {
  border-width: 2.5px 0;
  border-bottom-color: #e9e9e9;
  border-top-color: #e9e9e9;
}

.report-table th,
.report-table td {
  font-family: inherit;
  color: #707070;
  font-size: 13px;
  line-height: 16px;
}

.report-table .MuiTableHead-root .MuiTableRow-root {
  background-color: #fff;
  border-bottom: 3px solid #00000029;
}

.report-table .MuiTableRow-root {
  background-color: #fafafa;
  border-bottom: 3px solid #fff;
}

.report-table .MuiTableCell-root {
  border: none;
}

.report-table .table-header-cell {
  font-weight: 600;
}

.report-table .table-header-cell input {
  width: 100%;
  margin-top: 8px;
  background: #e9e9e9;
}
.report-table .table-header-cell.completion-timeline input {
  width: auto;
}

.MuiBreadcrumbs-li a.breadcrumb-link {
  color: #707070 !important;
}

.nowrap {
  white-space: nowrap;
}

/* ****************************************************
*********************END REPORTS************************
**************************************************** */
.text-inputanalysis{
  color:#407775;
}
.text-inputinsights{
  color:#707070;
}
.font-16{
  font-size: 16px;
}
.bg-inputanalysis{
  background: #EFF6F5 !important;
  border: 1px solid #00366D26 !important;
}
.borderright{
  border-right: 1px solid #407775;
}
.pd-left{
padding-left: 35px;
}
.pd-right{
  padding-right:35px;
}


.editable {
  position: relative;
}

.input-container {
  display: flex;
  align-items: center;
}

.icon-insight {
  width: 18px; /* Adjust the width and height as needed */
  height: 18px;
  cursor: pointer;
  margin: 0 -30px; /* Adjust the margin as needed to control the spacing between the icons and the input field */
}

.save-icon {
  display: none; /* Initially hide the save icon */
}

.editing .save-icon {
  display: inline; /* Show the save icon when in edit mode */
}
.card-title{
  font-size:14px !important;
font-weight:500;
}
.card-text{
  font-size:13px !important;
}
.react-multi-carousel-list{
  position: initial !important;
}
.react-multiple-carousel__arrow--left{
  left: calc(-2% + 1px) !important;
}
.react-multiple-carousel__arrow--right{
  right: calc(-2% + 1px) !important;
}
.swal2-success{
  border-color: #fff !important;
  color:#fff !important;
}
.swal2-confirm{
  width:100px !important;
}
.modal-footer{
  padding-bottom: 40px;
  padding-top:10px;
}
.modal-header {
  border-bottom: 0px solid #fff !important;
}
/* Style for the employee box container for social dashboard */
.employee-box {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  height: 120px;
  margin: 0 auto; /* Center the box */
}

/* Style for the employee box header */
.employee-box-header {
  font-size: 11px;
  font-weight:500;
  margin-bottom: 10px;
  word-wrap: break-word;
}
/* Style for the employee box header */
.employee-box-header-water {
  font-size: 20px;
  font-weight:500;
  margin-bottom: 10px;
  word-wrap: break-word;
}

/* Style for the employee box content */
.employee-box-content {
  font-size: 16px;
  color: #407775; /* Change the color as needed */
  font-weight: 600;
}
.table-responsive-social {
  overflow-x: auto;
}

.custom-table-social {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000; /* Border style */
}

.custom-table-social th,
.custom-table-social td {
  border: 1px solid #000; /* Border style */
  padding: 5px; /* Padding */
  font-size: 12px; /* Font size */
  text-align: left;
}

/* Reduce font size for mobile */
@media screen and (max-width: 600px) {
  .custom-table-social th,
  .custom-table-social td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }
}
.charts{
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin-bottom: 10px;
  height: 100%;
}
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.page-item {
  margin: 0 2px;
}

.page-link {
  text-decoration: none;
  color: #333;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s;
  display: inline-block;
}

.page-link:hover {
  background-color: #eee;
}

/* .active {
  background-color: #007bff;
  color: #fff;
} */

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #407775;;
  border-color: #407775;;
}

/* ------------------------------
   SideNavigation.js
   ------------------------------ */
/* .logo-container {
  margin-bottom: 20px;
} */

.list-icon-collapse {
  min-width: 56px !important;
  margin-left: 20px;
  color: #fff !important;
}

.list-icon-submenu-collapse {
  min-width: 56px !important;
  margin-left: 50px;
  color: #fff !important;
}

.icon-list-collapse-active,
.icon-list-active:hover {
  text-decoration: none;
  background-color: #fff !important;
  color: #407775 !important;
  width: auto;
  border-radius: 0;
  margin-left: 0;
  padding-left: 0;
}

.icon-list-item .green-btn-list-item {
  text-wrap: nowrap;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #407775 !important;
  background-color: #407775 !important;
  background: linear-gradient(to bottom, #407775 0%, #407775 100%) !important;
}
.br-bottom{
  border-bottom:0px !important;
}
.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #E3EAE9 !important;
  border-radius: 3px !important;
  padding: 2px !important;
  background-color: #FAFAFA !important;
  color: inherit;
  margin-left: 3px;
}
.dataTables_filter{
  display:none !important;
}
.global-search{
float:inline-end;
}
.mr-20{
  margin-right: 20px;
}
/* ------------------------------
   SideNavigation.js
   ------------------------------ */

.green-btn-list-item {
  text-wrap: nowrap;
}

.list-icon {
  min-width: 35px !important;
  margin-left: 20px;
  color: #fff !important;
}

.icon-list-active,
.icon-list-active:hover {
  background-color: #fff !important;
  width: 220px !important;
  border-radius: 10px !important;
  margin-left: 12px !important;
  padding-left: 0px !important;
}

.icon-list-active span {
  color: #407775 !important;
  font-weight: 400 !important;
}

.icon-list-collapse-active {
  background-color: #fff !important;
  width: auto;
}

.icon-list-active-submenu,
.icon-list-active-submenu:hover {
  background-color: #fff !important;
  border-radius: 10px !important;
  padding-left: 8px !important;
}
.icon-list-active-submenu span {
  color: #407775 !important;
  font-weight: 400 !important;
  padding-left: 7px !important;
  text-wrap: nowrap;
}

/* Add this style for the tree view lines */
.submenu-list {
  position: relative;
}

.submenu-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: -16px;
  bottom: 24px;
  width: 1px;
  background-color: #ccc;
}

.submenu-list .submenu-item {
  position: relative;
}

.submenu-list .submenu-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
  width: 12px;
  height: 1.2px;
  background-color: #ccc;
}
.main-menu-item{
  color:#fff !important;
  text-decoration: none !important;
  font-family: 'Noto Sans', sans-serif !important;
}
.main-menu-item:hover{
  color:#fff !important;
  text-decoration: none !important;
  font-family: 'Noto Sans', sans-serif !important;
}
.main-menu-item:active,
.main-menu-item-active {
  color: #fff !important;
  text-decoration: none !important;
  font-family: 'Noto Sans', sans-serif !important;
  /* Add any other styles for the active state */
}
.sub-menu-close{
  color:#707070 !important;
  text-decoration: none !important;
  font-family: 'Noto Sans', sans-serif !important;
  font-size:15px !important;
}
.sub-menu-close:hover{
  color:#707070 !important;
  text-decoration: none !important;
  font-family: 'Noto Sans', sans-serif !important;
  font-size:15px !important;
}