.pv-container {
  display: flex;
  flex-direction: column;
}

.pv-design-element {
  margin-top: 20px;
  font: normal normal normal 14px/17px Noto Sans;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.54;
  display: flex;
  align-items: center;
}

.pv-light-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: regular;
  font-size: 14px;
  color: #707070;
  letter-spacing: 0;
  line-height: 17px;
}

.pv-dark-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #707070;
  letter-spacing: 0;
  line-height: 17px;
}

.pv-header-content {
  margin-top: 20px;
}

.pv-header-content h1 {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-size: 26px;
  color: #494949;
  letter-spacing: 0;
  line-height: 40px;
}

.pv-header-content p {
  font-family: "Noto Sans", sans-serif;
  font-weight: regular;
  font-size: 12px;
  color: #707070;
  letter-spacing: 0;
  line-height: 17px;
}

.pv-content-container {
  display: flex;
  flex-wrap: wrap;
}

.pv-content {
  background-color: #fff;
  padding: 30px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pv-box {
  margin-bottom: 20px;
}

.pv-box h2 {
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #494949;
  letter-spacing: 0;
  line-height: 30px;
}

.pv-box p {
  font-family: 'Noto Sans', sans-serif;
  font-weight: regular;
  font-size: 12px;
  color: #707070;
  letter-spacing: 0;
  line-height: 17px;
}

.pv-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.pv-table th,
.pv-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.pv-table th {
  background-color: #f2f2f2;
  text-align: left;
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #494949;
  letter-spacing: 0;
  line-height: 17px;
}

.pv-table td {
  font-family: 'Noto Sans', sans-serif;
  font-weight: regular;
  font-size: 12px;
  color: #707070;
  letter-spacing: 0;
  line-height: 17px;
}

.pv-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 10px;
}

.back-buttonn {
  margin-top: 20px;
  margin-left: 0px; /* Add margin top to move the button down */
  background: #0A80A0; /* Add some custom styles for the button */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.back-buttonn:hover {
  background: #006d86; /* Add hover effect */
}