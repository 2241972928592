:root {

  /* Colors: */
  --unnamed-color-35561f1a: #35561F1A;
  --unnamed-color-f5f5f5: #F5F5F5;
  --unnamed-color-1f4d7c12: #1F4D7C12;
  --unnamed-color-707070: #707070;
  --unnamed-color-00366d: #00366D;
  --unnamed-color-2a2828: #2A2828;
  --unnamed-color-4b4848: #4B4848;
  --unnamed-color-ffffff: #FFFFFF;
  --unnamed-color-35561f: #35561F;
  
  /* Font/text values */
  --unnamed-font-family-helvetica: Helvetica;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-20: 20px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-21: 21px;
  --unnamed-line-spacing-24: 24px;
  }
  
  /* Character Styles */
  .unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-helvetica);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-21);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-35561f);
  }
  .unnamed-character-style-2 {
  font-family: var(--unnamed-font-family-helvetica);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-35561f);
  }

  @font-face {
    font-family: "NourisheInline";
    src: local("NourisheInline"),
     url("./fonts/nourishe/NourisheInline.ttf") format("truetype");
    font-weight: normal;
    }


    
 body {
  overflow-x: hidden;
}

/* Toggle Styles */



#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  /* left: 250px; */
  width: 0;
  height: 100%;
  /* margin-left: -250px; */
  overflow-y: auto;
  background: #407775;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

/* #page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
  top: 63px;
} */
.log-padding {
  padding-top: 23px;
    padding-right: 50px;
}
.login-wrapper {
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--unnamed-color-1f4d7c12) 0% 0% no-repeat padding-box;
  background: #1F4D7C12 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  position: fixed;
}
.login-image {
  width: 85%;
}
.login-wrapper-div {
  /* top: 210px;
  left: 307px;
  width: 1307px;
  position: fixed;
  height: 773px;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000029;
  opacity: 1;
  top: 50%;
    left: 50%;
    display: block;
    position: absolute;
    /* background: url(images/background.png) no-repeat center center; */
    width: 1249px;
    /* height: 417px; */
    margin-top: -208.5px;
    margin-left: -375px;
    width: 58%;
    height: 72%;
}
.hr-mar {
  margin: 2rem;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}
.data-div {
  font-size: 26px;
}
.data-div h5 {
  font-size: 30px;
  padding-bottom: 31px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255,255,255,0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}
#chat-with-us-card {
  position: fixed;
  bottom: 10px;
  right: 50px;
  width: 170px;
  background-color: #407775;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-body-bot {
  display: flex;
  padding: 1rem;
  align-items: center;
}

.card-title-bot {
  font-size: 1rem;
  font-weight: bold;
  color: white;
}


