.chat-container {
  display: flex;
  flex-direction: column;
  /* height: 400px; Adjust the height according to your layout */
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  overflow-y: auto;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #eeeeee;
  opacity: 1;
  padding-left: 6em;
  padding-right: 6em;
}
/* Style for chat container when chat is started */
.chat-started {
  background: #fff;
}
.chat-messages {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 5px;
  height: 55vh;
  overflow-y: auto;
}
.chat-mainbox {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 12px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
}
.chat-mainbox-citation {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
}
.chat-form {
  display: flex;
  flex-direction: column;
  background: #fff;

  position: sticky;
  flex: 0 0 100px;
  padding: 12px 24px 24px;
  width: calc(100% - 100px);

  margin-bottom: 50px;
  margin-top: 8px;
}
.user-icon {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  padding: 3px;
  background: white;
}
.bot-icon {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  padding: 3px;
  background: white;
}
.message {
  margin: 5px;
  max-width: 100%;
}

.user-message {
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  color: #000;
}
.botmessage {
  font-size: 13px;
  letter-spacing: 0.08px;
  color: #5f5c5c;
  opacity: 1;
  line-height: 20px;
  word-wrap: break-word;
}
.user-message .message-text {
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  color: #000;
}

.ecobot-message {
  align-self: flex-start;
}

.ecobot-message .message-text {
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 8px;
  color: #000;
}

.message-time {
  font-size: 10px;
  color: #777;
  padding-top: 10px;
} /* Add to your existing CSS file */
/* CSS Styles */
/* Add to your existing CSS file */

/* Style for buttons */
/* CSS Styles */
/* Add to your existing CSS file */

/* Style for buttons */
.option-button {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 8px 16px;
  background-color: #407775;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option-button:hover {
  background-color: #407775;
}

.send-button {
  padding: 8px 16px;
  background-color: #407775;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* .send-button:hover {
  background-color: #407775;
} */

/* Style for input box */
.input-box {
  padding: 18px;
  margin-top:15px;
  width: 100%; /* Adjust width as needed */
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 0px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #aaaaaa;
  /* margin-left: -228px; */
  
}

.input-box:focus {
  outline: none;
  border-color: #e9efef;
  border-bottom-color: #215dec;

}

/* Style for scope options */
.scope-options {
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 30px;
}

.scope-option-button {
  margin: 5px;
  padding: 8px 16px;
  background-color: #407775;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scope-option-button:hover {
  background-color: #407775;
}
.fn-bold {
  font-weight: 600;
}

.bot-message {
  background: #e9efef;
  /* background-color: #fff; */
  padding: 8px;
  border-radius: 8px;
  color: #000;
  margin-bottom: 25px;
}
.bot-message-content {
  display: flex;
  align-items: center;
}

.bot-text {
  margin-left: 10px; /* Adjust margin as needed */
}

.disclaimer {
  font-size: 10px;
  color: #5f5c5c;
  margin-top: 5px;
  position: absolute;
  bottom: 0;
  right: 160px;
}
/* Style for chat image */
.chat-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px; /* Adjust margin as needed */
}

.chat-image img {
  max-width: 100%; /* Ensure the image doesn't exceed container width */
}
/* CSS for icon styles */
.action-icons {
  display: flex;
  align-items: center;
 
}

.action-icon {
  margin-right: 5px;
  cursor: pointer;
  width: 26px;
  height: 26px;
  background: #fff;
  padding: 6px 0px 6px 0px;
  border-radius: 50%;
}

.edit-icon-chat {
  margin-left: 20px !important;
  cursor: pointer;
  width: 25px !important;
  height: 25px !important;
  background: #fff;
  padding: 4px;
}
.profile-icon {
  margin-right: 10px;
}
.pd-l-40 {
  padding-left: 61px;
}
.csrd-title {
  font-size: 14px;
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  color: #5f5c5c;
}
.user-box {
  padding: 4px;
  border-radius: 50px;
  width: 250px;
  height: 45px;
}
.username-text {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  padding-left: 2px;
}

.username-text-side {
  color: #5f5c5c;
  font-size: 12px;
  font-weight: 600;
  padding-left: 2px;
}

.typewriter {
  overflow: hidden; 
  margin: 0 auto; 
  letter-spacing: 0.05px; 
  margin-bottom: 15px;
  animation: typing 1.5s steps(40, end);

}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.AI-text-box {
  float: right;
}
.ai-text {
  font-size: 12px;
  color: #5f5c5c;
  padding: 5px;
}
.input-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.input-box {
  padding-right: 40px; /* Adjust spacing for the icon */
  height: 150%;
  /* border-bottom-color: #215dec; */
}

.send-button {
  position: absolute;
  right: 50px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(315deg);
}

/* .send-button:hover {
  opacity: 0.8;
} */

.send-button:focus {
  outline: none;
}

.send-button > svg {
  width: 32px;
  height: 32px;
  fill: #ffffff;
  border-radius: 50%;
  background-color: #000;
  padding: 8px;
}

.bot-typing::after {
  content: "";
  display: inline-block;
  animation: loadingDots 1s infinite;
}

@keyframes loadingDots {
  0% {
    content: ".";
  }
  25% {
    content: "..";
  }
  50% {
    content: "...";
  }
  75% {
    content: "";
  }
}
.chat-start {
  width: 45%;
}
.font-13{
  font-size: 11px;
  text-align: right;
  color: #5F5C5C;
}
.mt20{
  margin-top: 20px;
}
.mb20{
  margin-bottom: 20px;
}
.citation-acc {
  background: transparent !important; 
  margin-bottom: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
  margin-top: -12px !important;
}
.citation-acc::before {
  height: 0px !important;
}
.pdf-icon {
  width: 20px;
}
.accordion-content {
  height: 58vh !important;
  overflow-y: auto !important;
  font-size: 13px; /* Adjust font size as needed */
  line-height: 16px; /* Adjust line height as needed */
  color: #5f5c5c;
  word-wrap: break-word;
  text-wrap: wrap;
}
.accordion-history {

  overflow-y: auto !important;
  font-size: 13px; /* Adjust font size as needed */
  line-height: 16px; /* Adjust line height as needed */
  color: #5f5c5c;
  word-wrap: break-word;
  text-wrap: wrap;
}
.dummy-heading {
  font-size: 13px;
  line-height: 22px;
  word-wrap: break-word;
  font-weight: 600;
  cursor: pointer;
}
.accordion-summ {
  font-size: 14px;
  font-weight: 600;
  color: #5f5c5c;
  width:150px;
  margin-top: 0px !important;
  padding: 0px !important;
}
/* Default styles */
.ecobot-h1 {
  font-size: 30px;
  color: #3d3d3d;
  font-weight: bold;
  line-height: 38px;
}

.ecobot-text {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 500;
  line-height: 22px;
}

.ecobot-login-text {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: bold;
  line-height: 24px;
}
.linkedin-btn-img {
  width: 70%;
  cursor: pointer;
}
/* For devices up to 768px width (e.g., smartphones) */
@media (max-width: 768px) {
  .ecobot-h1 {
    font-size: 22px;
    line-height: 30px;
  }

  .ecobot-text {
    font-size: 14px;
    line-height: 20px;
  }

  .ecobot-login-text {
    font-size: 18px;
    line-height: 22px;
  }
  .linkedin-btn-img {
    width: 80%;
    cursor: pointer;
  }
  .dark-bg-ai {
    height: 50vh;
    background: transparent linear-gradient(178deg, #F1F1F1AD 0%, #7979790D 100%) 0% 0% no-repeat padding-box;
}
.white-bg-login {
  background-color: #fff !important;
  height: 50vh;
}
.aichat-img {
  display: flex;
  justify-content: center;
padding: 10px;
  margin: 0;
}
.landing-img {
  width: 80%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.green-logo {
  width: 200px;
  padding-top: 15px;
  padding-bottom: 30px;
  padding-left: 30px;
}
.pd-l-40 {
  padding-left: 15px;
}
.pd-r50 {
  padding-right: 15px;
}
.font-13 {
  font-size: 10px;
  text-align: right;
  color: #5F5C5C;
}
.bot {
  background: #E9EFEF;
  padding: 5px;
  border-radius: 8px;
  color: #5F5C5C;
  margin-bottom: 15px;
  font-size: 11px;
  line-height: 19px;
  word-wrap: break-word;
  white-space: normal;
  padding-right: 10px;
 
}
.user {
  color: #5F5C5C;
  font-size: 11px;
  line-height: 19px;
  padding-right: 10px;
}
.csrd-title {
  font-size: 12px;
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  color: #5f5c5c;
}
.chat-not-started {
  background-color: white;
  width: 100%;
}
.citation-text {
  font-size: 12px;
  line-height: 22px;
  color: #5F5C5C;
}
.sidebar-content {
  padding: 15px;
  font-size: 11px;
  line-height: 20px;
  color: #5F5C5C;
  word-wrap: break-word;
  white-space: normal;
}
.dummy-heading {
  font-size: 11px;
  line-height: 19px;
  word-wrap: break-word;
  font-weight: 600;
  cursor: pointer;
}
.chat-container.sidebar-close .chat-window {
  width: 100%;
  margin: 0 auto;
}

.chat-container.sidebar-open .chat-sidebar {
  width: 98%;
}

.chat-window {
  flex-grow: 1;
  overflow-y: auto;
  height: 80vh;
  position: relative;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  padding: 20px;
  border-radius: 22px;
  max-height: calc(100vh - 100px);
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  overflow-y: auto;
  background: #e9efef 0% 0% no-repeat padding-box;
  border: 1px solid #eeeeee;
  opacity: 1;
}
.input-box {
  padding: 12px;
    padding-right: 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 22px;
  margin-right: 10px;
  font-size: 12px;
  transition: border-color 0.3s ease;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #aaaaaa;
  outline: none;
  flex-grow: 1;
}
.chat-start {
  width: 75%;
}
.chat-box-msg {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.custome__header{
  display: none;
}
.mobile-header {
  background-color: #407775; /* Add your desired background color */
  padding: 10px;
}

.mobile-header .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-header .navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000; /* Add your desired text color */
}

.mobile-header .img-logo {
  width: 40px;
  margin-right: 5px;
  height: 40px;
}

.mobile-header .user-info {
  display: flex;
  align-items: center;
  float: right;
  margin-right: 5px;
}

.mobile-header .bot-icon {
  width: 40px;
  margin-right: 5px;
  height: 40px;
}

.mobile-header .username-text {
  margin-right: 5px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  padding-left: 2px;
  
}
.mobile-user{
  color: #fff;
  font-size:22px;
}
.mobile-header .logout-btn {
  color: #fff; /* Add your desired text color for the logout button */
  text-decoration: none;
}

}

/* For devices between 769px and 1024px width (e.g., tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .ecobot-h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .ecobot-text {
    font-size: 16px;
    line-height: 22px;
  }

  .ecobot-login-text {
    font-size: 22px;
    line-height: 26px;
  }
  .linkedin-btn-img {
    width: 60%;
    cursor: pointer;
  }
  .pd-l-40 {
    padding-left: 15px;
  }
  .font-13 {
    font-size: 10px;
    text-align: right;
    color: #5F5C5C;
  }
  .bot {
    background: #E9EFEF;
    padding: 5px;
    border-radius: 8px;
    color: #5F5C5C;
    margin-bottom: 15px;
    font-size: 11px;
    line-height: 19px;
    word-wrap: break-word;
    white-space: normal;
    padding-right: 10px;
   
  }
  .user {
    color: #5F5C5C;
    font-size: 11px;
    line-height: 19px;
    padding-right: 10px;
  }
  .custome__header{
    display: none;
  }
  .mobile-header {
    background-color: #407775; /* Add your desired background color */
    padding: 10px;
  }
  
  .mobile-header .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .mobile-header .navbar-brand {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000; /* Add your desired text color */
  }
  
  .mobile-header .img-logo {
    width: 40px;
    margin-right: 5px;
    height: 40px;
  }
  
  .mobile-header .user-info {
    display: flex;
    align-items: center;
    float: right;
    margin-right: 5px;
  }
  
  .mobile-header .bot-icon {
    width: 40px;
    margin-right: 5px;
    height: 40px;
  }
  
  .mobile-header .username-text {
    margin-right: 5px;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    padding-left: 2px;
    
  }
  .mobile-user{
    color: #fff;
    font-size:22px;
  }
  .mobile-header .logout-btn {
    color: #fff; /* Add your desired text color for the logout button */
    text-decoration: none;
  }
}

/* For devices between 1025px and 1440px width */
@media (min-width: 1025px) and (max-width: 1440px) {
  /* No changes needed for font sizes and line heights */
  .ecobot-h1 {
    font-size: 30px;
    line-height: 38px;
  }

  .ecobot-text {
    font-size: 16px;
    line-height: 22px;
  }

  .ecobot-login-text {
    font-size: 22px;
    line-height: 30px;
  }
  .linkedin-btn-img {
    width: 80%;
    cursor: pointer;
  }
  .mobile-header {
    display: none;
   }
}

/* For devices between 1441px and 1920px width */
@media (min-width: 1441px) and (max-width: 1920px) {
  /* No changes needed for font sizes and line heights */
  .ecobot-h1 {
    font-size: 42px;
    line-height: 55px;
  }

  .ecobot-text {
    font-size: 22px;
    line-height: 35px;
  }

  .ecobot-login-text {
    font-size: 26px;
    line-height: 42px;
  }
  .linkedin-btn-img {
    width: 80%;
    cursor: pointer;
  }
  .mobile-header {
    display: none;
   }

   .general-questions {
    position: relative;
    display:block;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 10px;
  }
  
  .general-question-round {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    background-color: #f1f1f1;
    padding: 10px 20px;
    border-radius: 25px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
  }
  
  .general-question-round:hover {
    background-color: #e1e1e1;
  }

  .welcome-message {
    padding-top: 30px; 
    font-size: 1.2em; 
    font-weight: bold; 
    text-align: center;
  }
  

  
}
