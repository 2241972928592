.project-content {
    /* margin-left: 200px; /* Adjusted margin-right */
    /* margin-top: 50px; */ 
    position:absolute;
    top: 25%;
    left: 25%;
    padding-bottom: 40px;
    background-color: #fff;
    /* padding: 30px; Adjusted padding */
    width: calc(75% - 200px);
    height: 50vh;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }



  .project-main-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}