/* Styling for the chat container */
.chat-container {
    display: flex;
  height: 100%;
}

/* Styling for the chat window */
.chat-window {
    flex-grow: 1;
    overflow-y: auto;
    /* height: 80vh; */
    position: relative;
    display: flex;
    max-width: 100%;
    
    flex-direction: column;
 background-color: #fff;
 padding: 20px;
 border-radius: 22px;
 max-height: calc(100vh - 100px);
}
.chat-not-started {
    background-color: white;
    width: 100%;
  }
.messages-container {
    flex: 1;
    overflow-y: auto;
  }
/* Styling for the chat window when sidebar is open */
.chat-container.sidebar-open .chat-window {
    /* width: 70%; Set width to 70% when sidebar is open */
    width: 100%;
    margin:none !important;
}
.chat-container.sidebar-close .chat-window{
    width: 100%; /* Set width to 70% when sidebar is open */
    margin: 0 auto;
  
}
/* Styling for the chat sidebar */
.chat-sidebar {
    width: 0;
   
    transition: width 0.3s ease;
    position: absolute;
    right: 0;
    background-color: #fff;
    border-radius: 20px;
    flex-basis: 20%; /* Adjust as needed */
 overflow-y: auto;
    /* height: 80vh; */
    max-height: calc(100vh - 100px);
}


/* Styling for the sidebar content */
.sidebar-content {
    padding: 15px;
    font-size: 13px;
    line-height: 23px;
    color: #5F5C5C;
    word-wrap: break-word;
    white-space: normal;
}

/* Styling for the close button */
.close-sidebar-button {
    background-color: #ccc;
    color: #333;
    border: none;
    padding: 8px 12px;
    margin-bottom: 10px;
    cursor: pointer;
    float: right;
}

/* Styling for the toggle sidebar button */
.toggle-sidebar-button {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 9999;
}

/* Styling for the input container */
.input-container {
   
    padding: 10px;
   align-items: center;
   /* padding-left: 8em; */
   width: 100%;

    
}
.chat-box-msg{
    height: 90vh;
    overflow-y: auto;
}
.input-container.sidebar-open {
  
  
  }
.input-box {
    flex-grow: 1;
  margin-right: 10px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  outline: none;
  width: 75%;
  }
/* Styling for the input box */
.input-container input {
    /* flex: 1;
    padding: 10px;
    margin-right: 10px; */
}

/* Styling for the send button */
.input-container button {
    padding: 10px 20px;
  background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
}

/* Styling for the sidebar when open */
.chat-container.sidebar-open .chat-sidebar {
    width: 30%;
}
.bot{
    background: #E9EFEF;
    /* background-color: #fff; */
    padding: 8px;
    border-radius: 8px;
    color: #5F5C5C;
    margin-bottom: 25px ;
    font-size: 13px;
    line-height: 23px;
    word-wrap: break-word;
    white-space: normal;
    padding-right: 55px;
}
.user{
    background: #fff;
    /* background-color: #fff; */
    padding: 8px;
    border-radius: 8px;
    color: #5F5C5C;
    margin-bottom: 25px ;
    font-size: 13px;
    padding-right: 55px;
    line-height: 23px;

}
.bot-icon {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    padding: 3px;
    background: white;
}
.clear-chat-icon{
    width: 30px;
    height: 30px;
    color: #000;
    cursor: pointer;
}
.chat-messages {
    flex: 1; /* Allow the chat messages to grow and take up remaining space */
    overflow-y: auto; /* Enable vertical scrolling if needed */
  }

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: #f7f7f7;
}
.citation-text{
    font-size: 14px;
    line-height: 24px;
    color: #5F5C5C;
}
.ecobot-main{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.main-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}
.icon-container {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    overflow: hidden;
    background: #fff; /* Background color of circular container */
  }
  
  .profile-icon {
    height: 70%;
    width: 70%;
    object-fit: cover;
    margin-top: 5px;
    margin-left: 6px;
  }
  .pd-r50{
    padding-right: 40px;
  }
  .ml-7{
    margin-left: -7px;
  }
  .citation-res{
    font-size: 14px;
    font-weight: 400;
  }