/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Noto Sans", sans-serif !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Design element styling */
.design-element {
  position: relative;
  top: 10px;
  left: 0px;
  width: 100%;
  height: 0px;
  font: normal normal normal 14px/17px Noto Sans;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.54;
  display: flex;
  align-items: center;
  padding-left: 0px;
  margin-bottom: -26px;
}

/* Light text styling */
.light-text {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: regular;
  font-size: 14px;
  color: #707070;
  letter-spacing: 0;
  line-height: 17px;
}

/* Dark text styling */
.dark-text {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: regular;
  font-size: 14px;
  color: #707070;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: bold;
}

/* Ensure the header-content is positioned correctly below the design element */
.container .header-content {
  margin-top: 0px;
}

.header {
  width: 100%;
  background-color: #333;
  color: white;
  padding: 15px;
  text-align: center;
}

.main-content {
  display: flex;
  flex-grow: 1;
  background-color: #f7f7f7;
}

.navigation {
  width: 200px; /* Adjusted width */
  background-color: #fff;
  color: #000 !important;
  height: 100%;
  position: fixed;
}

.navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navigation li a {
  color: #000;
  text-decoration: none;
  display: block;
}

.navigation li a:hover {
  background-color: #0A80A0;
  border-radius: 3px;
  padding: 3px;
}

.container {
  flex-grow: 1;
  background-color: #f7f7f7;
  padding: 30px; /* Adjusted padding */
  margin-left: 100px; /* Adjusted margin-left */
}

.header-content {
  margin-bottom: 0px;
}

.header-content h1 {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: bold;
  font-size: 26px; /* Adjusted font size */
  color: #494949;
  letter-spacing: 0;
  line-height: 30px; /* Adjusted line height */
}

.header-content .edit-icon {
  margin-left: 10px;
  font-size: 18px;
}

.header-content p {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: regular;
  font-size: 12px;
  color: #707070;
  letter-spacing: 0;
  line-height: 17px;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
}

/* Add this to your existing CSS */
.content {
  margin-right: 10px; /* Adjusted margin-right */
  background-color: #fff;
  padding: 30px; /* Adjusted padding */
  width: calc(60% - 20px);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: regular;
  font-size: 14px; /* Adjusted font size */
  color: #494949;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: capitalize; 
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #00366D26;
  border-radius: 3px;
  background-color: #FBFAFA;
  font-size: 14px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/19px var(--unnamed-font-family-Noto Sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-707070);
  text-align: left;
  font: normal normal normal 16px/19px Noto Sans;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.7;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #707070;
  font-family: "Noto Sans", sans-serif !important;
  font-weight: regular;
  font-size: 14px;
}

.custom-select {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.custom-select,
.select-items {
  background-color: #FBFAFA;
  padding: 10px;
  border: 1px solid #00366D26;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}

.select-items {
  display: none;
  position: absolute;
  background-color: #FBFAFA;
  border: 1px solid #00366D26;
  border-radius: 3px;
  z-index: 99;
  width: 100%;
}

.custom-select:focus .select-items {
  display: block;
}

.select-item {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}

.select-item:hover {
  background-color: #ddd;
}

.file-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.file-info {
  display: flex;
  align-items: center;
}

.file-icon {
  margin-right: 10px;
  color: #0A80A0;
}

.file-name {
  color: #000;
  text-decoration: none;
}

.check-icon {
  color: green;
}

.files-uploaded {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  width: calc(40% - 20px);
}

.upload-box {
  padding: 30px; /* Adjusted padding */
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Align buttons to the left */
}

button {
  padding: 10px 20px;
  border-color: rgba(0, 0, 0, 1.26);;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 20px;
}

button:first-child {
  border-color: rgba(0, 0, 0, 1.26);;
  border-width: thin;
}

button:last-child {
  /* background-color: rgb(43, 125, 163); */
  color: #fff;
  border: none;
}

.hidden {
  display: none;
}

/* Ensure to use a more specific selector */
.container .buttons .next-button {
  width: 144px;
  height: 53px;
  background: var(--unnamed-color-0a80a0) 0% 0% no-repeat padding-box;
  background: rgba(0, 0, 0, 1.26) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 1.26) !important;
  color: #fff !important;
  border: none !important;
  padding: 10px 20px !important;
  font-size: 20px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.container .buttons .next-button:hover {
  background-color: rgba(0, 0, 0, 1.26) !important;
}

a {
  color: #000;
  text-decoration: none;
}

#file-list {
  border: 2px solid #ccc;
  padding: 5px;
}

.fa-pen-to-square {
  padding-left: 20px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.26);
}

/* Project Details Page Styles */
.project-details {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.project-list-header {
  margin-bottom: 20px;
}

.project-list-header h2 {
  font-size: 24px; /* Adjusted font size */
  font: bold;
  font-family: "Noto Sans", sans-serif !important;
  font-weight: bold;
  color: #494949;
  letter-spacing: 0;
  line-height: 40px;
  
}

.project-list-header p {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: regular;
  font-size: 14px;
  color: #707070;
  letter-spacing: 0;
  line-height: 17px;
}

.search-bar {
  display: flex;

  align-items: center;
  width: 100%; /* Adjusted width */
  height: 60px; /* Adjusted height */
  /* background-color: #fff; */
  border: 0px solid #ccc;
  border-radius: 10px;
  margin: 20px 0; /* Adjusted margin */
 
  box-sizing: border-box;
 justify-content: space-between;
padding: 10px; 
}
 
.filter-sort, .add-projects {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  margin-left: 25px; /* Space between elements */
  font-family: "Noto Sans", sans-serif !important;
}

.filter-sort {
  position: relative;
  /* left: 75%; */
  display: flex;
    align-items: center;
}

.project-carousel {
  display: flex;
  align-items: center;
}

.project-cards {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  flex: 1;
}

/* Add this to your existing CSS */

/* Chat Icon Styles */

  .chat-iconn-img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease;
}

.chat-iconn-img:hover {
  transform: scale(1.1);
}

.invalid-input {
  border: 1px solid red;
}



/* Update project-card styles to include position relative */
.project-card {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(100% / 3 - 20px);
  margin-right: 10px;
  flex-shrink: 0;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  position: relative; /* Added position relative */
}

.menu-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.menu-dropdown {
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.menu-item {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.menu-item:hover {
  background-color: #f0f0f0;
}




.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Update project-category-box to have a very light background and dark text */
/* Specific styles for the first three project-category-box elements */
/* Remove random color generation and specify fixed colors for the first three cards */
.project-card:nth-child(1) .project-category-box {
  background-color: #DDEEFF; /* Light blue background color */
  color: #003366; /* Dark blue text color */
}

.project-card:nth-child(2) .project-category-box {
  background-color: #DDFFDD; /* Light green background color */
  color: #006600; /* Dark green text color */
}

.project-card:nth-child(3) .project-category-box {
  background-color: #FFDDDD; 
  color: #660000; 
}

.project-category-box {
  border-radius: 8px;
  padding: 8px; 
  margin-bottom: 8px; 
  margin-right: 6em;
  font-weight: bold;
}

.project-category {
  font-size: 10px; /* Adjusted font size */
  font-weight: bold;
}





.project-title {
  font-size: 16px; /* Adjusted font size */
  font-weight: bold;
  margin-bottom: 8px; /* Adjusted margin */
}

.project-description {
  font-size: 12px; /* Adjusted font size */
  color: #757575;
  margin-bottom: 8px; /* Adjusted margin */
}

.project-files-box {
  background: #F5F5F5;
  border-radius: 8px;
  padding: 8px; /* Adjusted padding */
  margin-bottom: 8px; /* Adjusted margin */
  font-family: "Noto Sans", sans-serif !important;
}

.project-files {
  font-size: 12px; /* Adjusted font size */
  color: #757575;
}

.project-files i {
  margin-right: 5px;
}

.project-date {
  font-size: 12px; /* Adjusted font size */
  color: #757575;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
}

.project-date i {
  margin-right: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-button {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px 8px; 
  margin: 0 3px; /* Reduced margin */
  cursor: pointer;
  font-size: 12px; 
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background-color: #f0f0f0;
}

.pagination-button.active {
  background-color: #0A80A0;
  color: white;
  border-color: #0A80A0;
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .project-card {
    width: calc(100% / 2 - 20px); /* Adjusted width */
  }
}

@media (max-width: 768px) {
  .project-card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .project-cards {
    flex-direction: column;
  }

  .carousel-button {
    display: none;
  }

  /* Add this CSS for the files icon */
.files-icon {
  width: 30px; /* Adjust the size as needed */
  height: 10px; /* Adjust the size as needed */
  margin-right: 10px; /* Add some space between the icon and the text */
}

/* DocumentUpload.css */
.blur {
  filter: blur(5px);
  pointer-events: none;
}


}
.ai-header{
  background: #e9ecef;
}
.attachment-icon {
  font-size: 25px;
  color: #000;
}