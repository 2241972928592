.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .popup-header h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .close-buttonn {
    background: transparent;
    border: none;
    font-size: 14px;
    cursor: pointer;
    padding: 4px;
    margin-top: 0px;
  }
  
  .popup-body {
    margin-bottom: 20px;
  }
  
  .file-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .file-item span {
    flex-grow: 1;
    word-break: break-all;
  }
  
  .delete-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  