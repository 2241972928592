.navigation {
  width: 250px;
  background-color: #f8f9fa;
  color: #333;
  height: 100%;
  position: fixed;
  top: 0;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  font-family: 'Arial', sans-serif;
}

.navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navigation li {
  padding: 6px;
  text-align: left;
}

.navigation li a {
  color: #333;
  text-decoration: none;
  display: block;
  /* padding: 5px 10px;  */
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 3px 10px; /* Padding for the menu item */
}

.navigation li:hover .menu-item {
  background-color: #0A80A0;
  color: #fff;
  border-radius: 3px;
}

.icon {
  margin-right: 10px; /* Adjust spacing between icon and text */
  width: 20px;
  text-align: center;
  position: relative;
}

.icon img {
  width: 15px;
  height: 15px;
}

.icon .white-icon {
  display: none;
}

.menu-item:hover .icon .grey-icon {
  display: none;
}

.menu-item:hover .icon .white-icon {
  display: inline;
}

.text {
  margin-left: 10px; /* Add space between icon and text */
  flex-grow: 1;
  font-size: 12px;
}

.home-text {
  padding-left: 10px;

}